@tailwind base;
@tailwind components;
@tailwind utilities;

.ranking-1 {
  position: relative;
}

.ranking-1::before {
  content: "👑";
  font-size: 1.5em;
  position: absolute;
  white-space: pre;
  display: inline;
  top: 6px;
  left: 8px;
  transform: translate(-50%, -22px) rotate(-30deg);
}

.error-message {
  font-weight: bold;
  color: red; /* or any color intended for error messages */
}

.success-message {
  font-weight: bold;
  color: rgb(17, 177, 110); /* or any color intended for success messages */
}

.input-field {
  padding: 10px 15px;
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  font-size: 16px;
  outline: none;
  width: 100%;
  box-sizing: border-box;
}

.input-field-error {
  border-color: red;
}

.input-field-valid {
  border-color: #ccc;
}

.winner-selection-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
}

.winner-selection-title {
  font-size: 1.25rem; /* 20px */
  margin-bottom: 0.5rem;
}

.hover-container {
  position: relative;
  display: inline-block;
}

.qr-code {
  display: none;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.hover-container:hover .qr-code {
  display: block;
}
.bg-yellow-100 {
  background-color: #fefcbf; /* Light yellow background */
}

.pagination {
  display: flex;
  list-style: none;
  padding: 0;
  justify-content: center;
  margin-top: 1rem;
}

.pagination li {
  margin: 0 0.5rem;
  cursor: pointer;
}

.pagination li a {
  color: #000;
  text-decoration: none;
}

.pagination li.active a {
  font-weight: bold;
  color: #007bff;
}

.pagination li.disabled a {
  color: #ccc;
  cursor: not-allowed;
}

.pagination li a:hover {
  text-decoration: underline;
}

/* Add this to your global CSS file or a specific CSS module */
.prediction-breakdown-container h2, .prediction-breakdown-container h3 {
  color: #2c3e50;
}

.prediction-breakdown-container table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}

.prediction-breakdown-container th, .prediction-breakdown-container td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.prediction-breakdown-container thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.prediction-breakdown-container tbody + tbody {
  border-top: 2px solid #dee2e6;
}
